<template>
    <div
        class="home"
        v-loading="loading"
    >
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">账户安全</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <span class="pic-box">
                            <img :src="$img.chemail" />
                        </span>
                        <p class="accont-slogin">修改绑定邮箱</p>
                    </div>
                </div>

                <div class="panner-bd modifypd">
                    <el-form
                        ref="accountForm"
                        :model="accountForm"
                        :rules="rules"
                        label-width="100px"
                        class="g-form"
                        style="width:500px;"
                    >
                        <el-form-item
                            label="当前绑定邮箱"
                            prop="phone"
                        >
                            <p
                                class="phone-txt"
                                v-text="userInfo.info.email"
                            ></p>
                        </el-form-item>

                        <el-form-item
                            label="新邮箱"
                            prop="mobile"
                        >
                            <el-input
                                type="mobile"
                                v-model="accountForm.mobile"
                                placeholder="请输入新邮箱"
                                auto-complete="off"
                            ></el-input>
                        </el-form-item>

                        <el-form-item
                            label="验证码"
                            prop="zma"
                        >
                            <el-input
                                type="email"
                                v-model="accountForm.zma"
                                placeholder="请输入邮箱验证码"
                                style="width:212px;"
                                auto-complete="off"
                            ></el-input>
                            <a
                                href="javascript:void(0)"
                                class="zma"
                                @click="getMobileCode"
                            >获取验证码</a>
                        </el-form-item>

                        <el-form-item class="btn-wrap">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="handleSubmit"
                                >确认修改</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "modifyMailbox",
    metaInfo() {
        return {
            title: "修改绑定邮箱 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            accountForm: {
                mobile: '',//邮箱地址
                zma: '',//验证码
                action: 3,//获取邮箱验证码 - 其他
                user_bindEmail: 1,//获取邮箱验证码 - 邮箱绑定，而不是发送短信
            },
            rules: {
                mobile: [
                    {
                        required: true,
                        message: "请输入邮箱",
                        trigger: ['change', 'blur']
                    },
                    {
                        type: 'email',
                        message: "请输入正确的邮箱地址",
                        trigger: ['change', 'blur']
                    }
                ],
                zma: [
                    {
                        required: true,
                        message: "请输入邮箱验证码",
                        trigger: ['change', 'blur']
                    },
                    {
                        min: 4,
                        max: 4,
                        message: "请输入4位有效邮箱验证码",
                        trigger: ['change', 'blur']
                    }
                ],
            }
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        //获取邮箱验证码
        getMobileCode() {
            let _this = this;
            _this.$refs["accountForm"].validateField('mobile', function (val, error) {
                if (!val) {
                    _this.loading = true;
                    _this.post(
                        "/user/user/getcode",
                        _this.accountForm,
                        data => {
                            _this.loading = false;
                            if (data.code == 200) {
                                _this.$alert('邮箱验证码已发送至您的个人邮箱，请查收后将获取验证码填入邮箱验证码输入框内完成邮箱绑定操作！', '温馨提示', {
                                    confirmButtonText: '确定',
                                    callback: action => { }
                                });
                            } else {
                                _this.$message.error(data.msg);
                                return;
                            }
                        }
                    );
                }
            });
        },

        //确认绑定邮箱
        handleSubmit() {
            let _this = this;
            _this.$refs["accountForm"].validate((valid, validInfoObj) => {
                if (valid) {
                    _this.post(
                        "/user/user/email",
                        _this.accountForm,
                        data => {
                            if (data.code != 200) {
                                _this.$message.error(data.msg);
                                return;
                            }
                            _this.$message.success('邮箱修改成功');
                            _this.$router.push('/user/setting/');
                        }
                    );
                }
            });
        },
    },
    created() { }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


